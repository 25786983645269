<script>
// Extensions
import View from '@/views/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
  name: 'About',

  metaInfo: { title: 'About Us' },

  extends: View,

  mixins: [
    LoadSections([
      'about-description',
      'who-we-are',
      // 'about-our-product',
      // 'customer-reviews',
      // 'we-help-your-success',
      // 'info-graph',
      // 'theme-features',
      // 'affiliates',
      // 'marketing',
      'newsletter-alt',
      'info-alt',
    ]),
  ],

  props: {
    id: {
      type: String,
      default: 'about',
    },
  },
}
</script>
